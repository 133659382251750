import { IMenu } from './types';
import config from '../../utils/config';
// import jwtDecode from 'jwt-decode';
const painelUrl = config.endpoints.PAINEL_MANDIC;
const painelUrlDevops = `${window.location.origin}/`;
console.log(painelUrlDevops);

// const verifyMenuOnCookeToDecodeItem = document.cookie
//   .split(';')
//   .filter((value: any) => {
//     return value?.includes('_MENUJWT_');
//   })[0]
//   ?.replace('_MENUJWT=_', '');
// const verifyMenuOnCookeToDecodeObj = {
//   verifyMenuOnCookeToDecodeItem,
// };

// const decodeMenuJwtdecode: { menu: [] } =
//   verifyMenuOnCookeToDecodeObj &&
//   jwtDecode(verifyMenuOnCookeToDecodeObj?.verifyMenuOnCookeToDecodeItem);
// const resultDecodeMenuJwt = decodeMenuJwtdecode && decodeMenuJwtdecode?.menu;

const arrayMocked = [
  {
    key: 'home',
    icon: 'home',
    list: [
      {
        name: 'Home',
        modules: [`${painelUrl}`],
        external: true,
      },
      {
        name: 'Produtos',
        modules: [`${painelUrl}solutions`],
        external: true,
      },
    ],
  },
  {
    key: 'cloud',
    name: 'Cloud',
    icon: 'assignment_turned_in',
    list: [
      {
        name: 'Servidores',
        modules: [`${painelUrl}servers`],
        external: true,
      },
      {
        name: 'Backup',
        modules: [`${painelUrl}Backup/backup?typeBackupId=1`],
        external: true,
      },
      {
        name: 'Gerenciar DNS',
        modules: [`${painelUrl}dnsmanager`],
        external: true,
      },
    ],
  },
  {
    key: 'devops',
    name: 'DevOps e Dados',
    icon: 'assignment',
    list: [
      { name: 'AWS Billing', modules: ['/billing'] },
      { name: 'Cloud Compliance', modules: ['/preventives'] },
      { name: 'Matriz de Resiliência', modules: ['/cmdb'] },
      { name: 'Projetos Ágeis', modules: ['/worklogs', '/tasks', '/schedule'] },
      // { name: 'Planos', modules: ['/plans'] },
    ],
  },
  {
    key: 'email',
    name: 'Email',
    icon: 'email',
    list: [
      {
        name: 'E-mail Corporativo',
        modules: [`${painelUrl}mailmanager`],
        external: true,
      },
    ],
  },
  {
    key: 'config',
    name: 'Configurações',
    icon: 'config',
    list: [
      {
        name: 'Dados Cadastrais',
        modules: [`${painelUrl}register`],
        external: true,
      },
      {
        name: 'Usuário adicional',
        modules: [`${painelUrl}additionalUser`],
        external: true,
      },
      {
        name: 'Configurar Acesso',
        modules: [`${painelUrl}passwordData/changePassword`],
        external: true,
      },
    ],
  },
  {
    key: 'financial',
    name: 'Financeiro',
    icon: 'financial',
    list: [
      {
        name: 'Dados de Pagamento',
        modules: [`${painelUrl}paymentdata`],
        external: true,
      },
    ],
  },
  {
    key: 'contact',
    name: 'Suporte/Contato',
    icon: 'contact',
    list: [
      {
        name: 'Chamados',
        modules: [`${painelUrl}support2/ListTickets`],
        external: true,
      },
      {
        name: 'Cancelar Serviços',
        modules: [`${painelUrl}support2/SelfCancel`],
        external: true,
      },
      {
        name: 'Wiki',
        modules: [`https://wiki.mandic.com.br/`],
        external: true,
      },
      {
        name: 'Status dos Serviços',
        modules: ['https://status.mandic.com.br'],
        external: true,
      },
    ],
  },
];
// const menuCookie: IMenu[] =
//   resultDecodeMenuJwt &&
//   resultDecodeMenuJwt.map((item: any) => {
//     return {
//       key: item.key,
//       menu: [],
//       icon: item.icon,
//       name: item?.name,
//       list: item.list.map((itemList: any) => {
//         const verifyNameModules = () => {
//           return item.name === 'DevOps e Dados';
//         };
//         const filterModules = () => {
//           const devopsedados = verifyNameModules()
//             ? itemList.modules.map((item: any) =>
//                 item.replace(`${painelUrlDevops}`, '/')
//               )
//             : itemList.modules;
//           return devopsedados;
//         };

//         const filterExternal = () => {
//           const devopsedados = verifyNameModules()
//             ? (itemList.external = false)
//             : (item.external = true);
//           return devopsedados;
//         };
//         return {
//           external: filterExternal(),
//           modules: filterModules(),
//           name: itemList.name,
//         };
//       }),
//     };
//   });
// console.log(menuCookie);

const menu: IMenu[] = arrayMocked;
// resultDecodeMenuJwt ? menuCookie : arrayMocked;

export default menu;
